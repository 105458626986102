const patientDetails = (state = {userRole: "nothing"}, action) => {
    switch (action.type) {
      case "PATIENT_DETAILS": {
        return  {...state, values : action.payload}
      }
      case "CHANGE_ROLE_EX": {
        return { ...state, userRole: action.payload }
      }
      default: {
        return state
      }
    }
}

export default patientDetails