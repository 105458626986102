const tempForNow=(patient)=>{
    var role = "nothing"
    if(patient.is_plan_expiry){
      var today = new Date()
      var expiry = new Date(patient.expiryDate)
      if(today.getTime() > expiry.getTime()){
        role = "expired"
      }else{
        role = "exercise"
      }
    }else{
      role = "session"
    }
    return role
  }
  
 export const setRoleEx = (patient)=>{
    // roles: new, session, exercise, expired, demo
    var role = "nothing"
    if(patient.isB2C){
      if(patient.isPaid){
        role = tempForNow(patient)
      }else{
        role = "new"
      }
    }else{
      role = tempForNow(patient)
    }
    return role
  }