import { history } from "../../../history"
import axios from "axios"
import { domain } from  "../../../backend"
import {setRoleEx} from "./roles"


export const loginWithJWT = (user, return_path, makeToast) => {
  return dispatch => {
    axios
      .post(domain+"/v1/patient/login", {
      }, {
        auth: {
          username: user.email,
          password: user.password
        }
      })
      .then(response => {
        if(response.data.status === 'done'){
          var loggedInUser
          var token = response.data.token
          axios.get(domain+"/v1/exercise/getalldata", {
              headers: {
                "x-access-token" : token
              }
            }).then(response => {
              if (response.data) {
                loggedInUser = response.data.patientDetails
                console.log(response.data.patientDetails)
                localStorage.setItem("token", token)
                // localStorage.setItem("patientData", JSON.stringify(response.data))
                dispatch({
                  type: "LOGIN_WITH_JWT",
                  payload: { loggedInUser, loggedInWith: "jwt", is_logged_in: true }
                })
                dispatch({
                  type: "PATIENT_DETAILS",
                  payload: response.data.patientDetails
                })
                dispatch({
                  type: "PATIENT_CONTRAST",
                  payload: response.data.contrast
                })
                dispatch({
                  type: "PATIENT_EXERCISES",
                  payload: response.data.exercises
                })
                dispatch({
                  type: "PATIENT_DATA_LOADED",
                  payload: true
                })
                dispatch({
                  type: "CHANGE_ROLE_EX",
                  payload: setRoleEx(response.data.patientDetails)
                })
                if (typeof return_path != 'undefined') {
                  history.push(return_path)
                }else{
                  history.push("/")
                }
                
              }
            }).catch(err => console.log(err))
        }else if(response.data.status === 'not_done'){
          if(response.data.data === 'user_not_found'){
            makeToast("User not found!")
          }else if(response.data.data === 'password_is_incorrect'){
              makeToast("Incorrect Password!")
          }else if(response.data.data === 'user_not_active'){
            makeToast("Account is disabled, contact your doctor")
          }
        }
      })
      .catch(err => makeToast("Something Went Wrong!"))
  }
}

export const fetchUserData = (token) => {
  return dispatch => {
    var loggedInUser
    axios.get(domain+"/v1/exercise/getalldata", {
        headers: {
          "x-access-token" : token
        } 
    }).then(response => {
      if (response.data) {
        console.log(response.data)
        loggedInUser = response.data.patientDetails
        dispatch({
          type: "LOGIN_WITH_JWT",
          payload: { loggedInUser, loggedInWith: "jwt", is_logged_in: true }
        })
        dispatch({
          type: "PATIENT_DETAILS",
          payload: response.data.patientDetails
        })
        dispatch({
          type: "PATIENT_CONTRAST",
          payload: response.data.contrast
        })
        dispatch({
          type: "PATIENT_EXERCISES",
          payload: response.data.exercises
        })
        dispatch({
          type: "PATIENT_DATA_LOADED",
          payload: true
        })
        dispatch({
          type: "CHANGE_ROLE_EX",
          payload: setRoleEx(response.data.patientDetails)
        })
        history.push("/")
      }}).catch(err => console.log(err))
  }
}

export const logoutWithJWT = () => {
  return dispatch => {
    localStorage.removeItem("token")
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    dispatch({
      type: "PATIENT_DETAILS",
      payload: {}
    })
    dispatch({
      type: "PATIENT_CONTRAST",
      payload: {}
    })
    dispatch({
      type: "PATIENT_EXERCISES",
      payload: []
    })
    dispatch({
      type: "PATIENT_DATA_LOADED",
      payload: false
    })
    dispatch({
      type: "PATIENT_DATA_READY",
      payload: false
    })
    history.push("/pages/login")
  }
}


export const changeRoleEx = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE_EX", payload: role })
}