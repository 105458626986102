const todayExercises = (state = {values: false}, action) => {
    switch (action.type) {
      case "TODAY_EXERCISES": {
        return  {...state, values : action.payload}
      }
      default: {
        return state
      }
    }
}

export default todayExercises