const dashboardExercises = (state = {}, action) => {
    switch (action.type) {
      case "DASHBOARD_EXERCISES": {
        return  {...state, values : action.payload}
      }
      default: {
        return state
      }
    }
}

export default dashboardExercises