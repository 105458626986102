const patientDataLoaded = (state = {loaded: false, ready: false}, action) => {
    switch (action.type) {
      case "PATIENT_DATA_LOADED": {
        return  {...state, loaded : action.payload}
      }
      case "PATIENT_DATA_READY": {
        return  {...state, ready : action.payload}
      }
      default: {
        return state
      }
    }
}

export default patientDataLoaded