const patientDayHistory = (state = {values:[]}, action) => {
    switch (action.type) {
      case "PATIENT_DAY_HISTORY": {
        return  {...state, values : action.payload}
      }
      default: {
        return state
      }
    }
}

export default patientDayHistory