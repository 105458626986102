import { combineReducers } from "redux"
import patientDetails from "./patientDetailsReducer"
import patientContrast from "./patientContrastReducer"
import patientExercises from "./patientExercisesReducer"
import patientDataLoaded from "./patientDataLoaded"
import todayExercises from "./todayExercises"
import dashboardExercises from "./dashboardExercises"
import patientDayHistory from "./patientDayHistory"

const patientReducer = combineReducers({
    patientDetails,
    patientContrast,
    patientExercises,
    patientDataLoaded,
    todayExercises,
    dashboardExercises,
    patientDayHistory
})

export default patientReducer
