const patientContrast = (state = {values:{}}, action) => {
    switch (action.type) {
      case "PATIENT_CONTRAST": {
        return  {...state, values : action.payload}
      }
      default: {
        return state
      }
    }
}

export default patientContrast