// export const domain = "http://192.168.1.2:5001"
// export const domain = "http://127.0.0.1:5000"
// export const razorPayKey = "rzp_test_Hv8hdt6mVpXhWX"

// export domain


// CureSee export const razorPayKey = "rzp_live_pHpSOELoWusDPH"
// export const razorPayKey = "rzp_live_Mgn7ksSKMtMWNb"
export const razorPayKey = "rzp_live_xQkYQRxu3juuhK"
// export const domain = "https://jatinkaushik-fantastic-chainsaw-q7wxjgvqjp62x64j-5000.preview.app.github.dev"
// export const domain = "https://jatinkaushik-fantastic-chainsaw-q7wxjgvqjp62x64j-5000.app.github.dev/"
export const domain = "https://apiv1.curesee.com"