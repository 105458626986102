import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  // Media,
  // Badge
} from "reactstrap"
// import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
// import classnames from "classnames"
// import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { logoutWithJWT } from  "../../../redux/actions/auth/loginActions"
import { changeMode } from  "../../../redux/actions/customizer/index"
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import { history } from "../../../history"
import Toggle from "react-toggle"
import "react-toggle/style.css"
import "../../../assets/scss/plugins/forms/switch/react-toggle.scss"



const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a"
        onClick={() => history.push("/editprofile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        // onClick={e => history.push("/pages/login")}
        onClick={e => props.logoutWithJWT()}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    isDarkTheme: false
  }

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
    this.props.changeMode('light')
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }
  
  handleTheme = (mode) => {
    this.setState({
      isDarkTheme: !this.state.isDarkTheme
    })
  }


  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">

        <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            {/* <Icon.Search size={21} data-tour="search" /> */}
            <Toggle
              checked={this.state.isDarkTheme}
              onChange={() => {
                if(!this.state.isDarkTheme ? this.props.changeMode('dark') : this.props.changeMode("light"))
                this.handleTheme()
              }}
              name="controlledSwitch"
              value="yes"
              icons={{
                checked: (
                  <Icon.Sun
                    size={13}
                    style={{ position: "absolute", top: "-2px" }}
                  />
                ),
                unchecked: (
                  <Icon.Moon
                    size={13}
                    style={{ position: "absolute", top: "-2px" }}
                  />
                )
              }}
            />
          </NavLink>
        </NavItem>

        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">Available</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
              {/* <User size="40" /> */}
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    values: state.auth.login
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({changeMode, logoutWithJWT}, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(NavbarUser)
// export default connect(mapStateToProps, { logoutWithJWT })(NavbarUser)
